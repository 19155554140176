div.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  div.content {
    flex: 1;
    position: relative;
  }
}
