@import 'club', 'footer', 'input';

button {
  appearance: none;
  padding: 12px 48px;
  border: none;
  background: linear-gradient(to left, lighten($primary, 2%), darken($primary, 2%));
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}
