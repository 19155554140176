div.input-wrapper {
  margin-bottom: 24px;
  label {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    padding-left: 12px;
  }
  input,
  div.tabs-wrapper {
    height: 48px;
    line-height: 48px;
    background: $border;
    width: 100%;
    border-radius: 80px;
    margin-top: 4px;
  }
  input {
    box-sizing: border-box;
    display: block;
    appearance: none;
    border: none;
    padding: 12px 16px;
    font-size: 14px;
    font-family: $font;
    outline: none;
  }
  div.tabs-wrapper {
    display: flex;
    div.tab-wrapper {
      flex: 1;
      text-align: center;
      border-radius: 80px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      &:hover {
        background: darken($border, 5%);
      }
      &:active {
        background: darken($border, 15%);
      }
      &.active {
        background: $primary;
        color: white;
      }
    }
  }
}
