div.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $background;
  padding: 24px;
  padding-bottom: 0;
  z-index: 17;
  transition: 480ms;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
    overflow: auto;
  }
}
