footer {
  height: $footer-height;
  border-top: 1px solid $border;
  padding: 0 16px;
  display: flex;
  align-items: center;
  z-index: 24;
  background: $background;
  transition: 320ms;
  position: fixed;
  z-index: 1024;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  &.hidden {
    transform: translateY(100%);
  }
  div.content {
    flex: 1;
    span {
      display: block;
      font-size: 14px;
      &.title {
        opacity: 0.4;
        margin-bottom: 4px;
      }
      &.subtitle {
        // font-size: 16px;
      }
    }
  }
}
