div.welcome-wrapper {
  position: fixed;
  h1 {
    padding-top: 48px;
    text-align: center;
  }
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1204;
    p {
      margin: 64px 0;
    }
  }
}

@keyframes confettiRain {
  0% {
    opacity: 1;
    margin-top: -100vh;
    margin-left: -200px;
  }

  100% {
    opacity: 1;
    margin-top: 100vh;
    margin-left: 200px;
  }
}

.confetti {
  opacity: 0;
  position: absolute;
  width: 1rem;
  height: 1.5rem;
  transition: 500ms ease;
  animation: confettiRain 8s infinite;
}

#confetti-wrapper {
  overflow: hidden !important;
}
