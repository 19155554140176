div.clubs-wrapper {
  // height: 100vh;
  // height: -webkit-fill-available;
  // overflow: auto;
  // padding-bottom: $footer-height;
  padding-top: 80px;
  padding-bottom: calc(12px + env(safe-area-inset-bottom, 20px));
  h1 {
    padding: 0 24px;
    font-size: 20px;
    background: white;
    margin: 0;
    margin-bottom: 12px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 16;
    height: 80px;
    line-height: 80px;
    transition: 320ms;
    &.scrolled {
      box-shadow: 0 0 12px rgba(black, 0.1);
    }
  }
}
