@import 'variables';
@import 'components/main', 'screens/main', 'layout/main';
:root {
  --border-color: #d7dbe3;
  --green: #0cd977;
  --red: #ff1c1c;
  --pink: #ff93de;
  --purple: #5767ed;
  --yellow: #ffc61c;
  --rotation: 0deg;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font;
  color: #333;
  height: 100vh;
  height: -webkit-fill-available;
  background: $border;
}
body {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px)
    env(safe-area-inset-left, 20px);
}

html {
  // scroll-snap-type: y mandatory;
  // scroll-snap-stop: always;
  // overflow-y: scroll;
}
