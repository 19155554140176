div.club-wrapper {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background: white;
  cursor: pointer;
  user-select: none;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
  div.banner {
    width: 100%;
    height: calc((100vw - 48px) * 1.4);
    margin: auto;
    overflow: hidden;
    border-radius: 24px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    span.title {
      display: block;
      text-align: left;
      font-size: 22px;
      font-weight: 500;
      color: #333;
    }
  }
  p {
    margin: 0;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
  }
}
