div.join-wrapper {
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
  header {
    div.back {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        padding-top: 2px;
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
